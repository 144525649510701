import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"

import Layout from "../components/layout.js"
import SEO from "../components/seo.js"
import ProjectImagesBox from "../components/projectImagesBox"

const MainWrapper = styled.main`
  max-width: calc(100% - 80px - 20vw);
  width: 100%;
  margin: 0 auto;
  @media (max-width: 1240px) {
    max-width: 100%;
  }
`

const ProjectPageTemplate = ({ data, location }) => {
  return (
    <Layout location={location}>
      <MainWrapper>
        <SEO
          title={data.markdownRemark.frontmatter.seo.browserTitle}
          description={data.markdownRemark.frontmatter.seo.description}
          keywords={data.markdownRemark.frontmatter.seo.keywords}
        />
        <ProjectImagesBox
          images={data.markdownRemark.frontmatter.images}
          title={data.markdownRemark.frontmatter.projectTitle}
          subtitle={data.markdownRemark.frontmatter.projectSubtitle}
          text={data.markdownRemark.frontmatter.description}
          movies={data.markdownRemark.frontmatter.movies}
        />
      </MainWrapper>
    </Layout>
  )
}

ProjectPageTemplate.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const pageQuery = graphql`
  query ProjectPageQuery($id: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        projectTitle
        projectSubtitle
        description
        movies {
          video {
            srcMp4
            srcWebM
          }
        }
        images {
          childImageSharp {
            id
            fluid(maxWidth: 920, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
            original {
              height
              width
            }
          }
        }
        seo {
          browserTitle
          description
          keywords
        }
      }
    }
  }
`

export default ProjectPageTemplate
