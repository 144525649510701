import React from "react"
import { useSpring, animated } from "react-spring"
import styled from "styled-components"

const ScrollingTextContainer = styled.div`
  visibility: ${({ index, movies, indexOfVideo }) =>
    index >= indexOfVideo && movies && indexOfVideo !== -1
      ? "hidden"
      : "visible"};
  position: absolute;
  top: 80px;
  right: 0;
  overflow: hidden;
`

const ScrollingTextWrapper = styled.div`
  will-change: transform;
  min-width: 100%;
  white-space: nowrap;
  text-transform: uppercase;
  mix-blend-mode: difference;
  color: #fff;
  pointer-events: none;
`

const ScrolledItem = styled.span`
  margin-right: 20px;
`

const ScrollingText = ({
  text,
  index,
  arrLength,
  movies,
  indexOfVideo,
  isLoaded,
}) => {
  // const [key, setKey] = useState(1)

  const scrolledTextArr = [...text, "||", ...text, "||", ...text, "||"]
  const scrolledText = scrolledTextArr.map((word, i) => (
    <ScrolledItem key={i}>{word}</ScrolledItem>
  ))

  const scrolledTextLength = scrolledTextArr.length
  const scrolledTextSpeed = scrolledTextLength * 10000

  const scrolling = useSpring({
    config: {
      duration: scrolledTextSpeed,
      mass: 300,
      tension: 500,
      friction: 500,
      clamp: true,
    },
    from: { transform: "translate3d(100%,0,0)" },
    to: {
      transform: isLoaded ? "translate3d(-100%,0,0)" : "translate3d(100%,0,0)",
    },
    // reset: true,
    // onRest: () => {
    //   setKey(key + 1)
    // },
  })

  return (
    <ScrollingTextContainer
      index={index}
      arrLength={arrLength}
      movies={movies}
      indexOfVideo={indexOfVideo}
    >
      <AnimatedText style={scrolling}>{scrolledText}</AnimatedText>
    </ScrollingTextContainer>
  )
}

const AnimatedText = animated(ScrollingTextWrapper)

export default ScrollingText
