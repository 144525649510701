import React, { useState, useEffect, useRef } from "react"
import Image from "gatsby-image"
import styled from "styled-components"
import { animated, useSpring } from "react-spring"

import ScrollingText from "../components/scrollingText.js"

import Back from "../assets/SVG/back.svg"
import Next from "../assets/SVG/next.svg"
import Mute from "../assets/SVG/mute.svg"
import Unmute from "../assets/SVG/unmute.svg"

const ProjectPageWrapper = styled.section`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 879px) {
    padding-top: 4px;
  }
`

const ImgBoxWrapper = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 40px;
  /* @media (max-width: 879px) {
    padding-bottom: 20px;
  } */
`
const BoxControlButton = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  align-self: ${({ flexEnd }) => (flexEnd ? "flex-end" : "center")};
  outline: none;
  cursor: pointer;
  img {
    width: 20px;
    @media (max-width: 879px) {
      width: 10px;
    }
  }
  @media (max-width: 879px) {
    align-self: center;
    height: 100%;
    padding: 0 5px;
  }
  @media (orientation: landscape) and (max-width: 879px) {
    align-self: center;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .scrollingText {
    position: absolute;
    top: 90px;
    width: 100%;
    height: auto;
    max-width: 920px;
    @media (max-width: 879px) {
      top: 0;
    }
  }
  .mute {
    width: 100%;
  }
`

const StyledVideoWrapper = styled.div`
  width: 100%;
  position: absolute;
  max-width: 920px;
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  &:after {
    content: "${({ title }) => title}";
    display: block;
    text-align: right;
    font-weight: 400;
    font-size: 0.8rem;
    color: inherit;
    text-transform: uppercase;
    letter-spacing: 3px;
    white-space: nowrap;
    padding-top: 20px;
    @media (max-width: 879px) {
      padding-top: 4px;
      content: "${({ title, subtitle }) => title + " " + subtitle}";
    }
  }
  &:before {
    content: "${({ subtitle }) => subtitle}";
    position: absolute;
    bottom: 20px;
    left: 0;
    margin-left: -50px;
    display: block;
    text-align: left;
    font-weight: 400;
    font-size: 0.8rem;
    color: inherit;
    text-transform: uppercase;
    letter-spacing: 3px;
    white-space: nowrap;
    transform: rotate(270deg);
    transform-origin: 0 0;
    @media (max-width: 879px) {
      margin-left: -18px;
      bottom: 4px;
      display: none;
    }
  }
`

const StyledVideo = styled.video`
  border: none;
  display: block;
  width: 100%;
  background-color: #033060a3;
`

const MuteButton = styled.button`
  background: none;
  border: none;
  outline: none;
  position: absolute;
  width: 40px;
  bottom: 50px;
  left: 20px;
  mix-blend-mode: difference;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 879px) {
    width: 20px;
    bottom: 30px;
    left: 10px;
  }
`

const StyledImage = styled(Image)`
  will-change: opacity, transform;
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  position: absolute !important;
  width: 100%;
  height: auto;
  max-width: ${({ width, height }) => (width > height ? "920px" : "500px")};
  overflow: visible !important;
  &:after {
    content: "${({ title }) => title}";
    display: block;
    text-align: right;
    font-weight: 400;
    font-size: 0.8rem;
    color: inherit;
    text-transform: uppercase;
    letter-spacing: 3px;
    white-space: nowrap;
    padding-top: 20px;
    @media (max-width: 879px) {
      padding-top: 4px;
      content: "${({ title, subtitle }) => title + " " + subtitle}";
    }
  }
  &:before {
    content: "${({ subtitle }) => subtitle}";
    position: absolute;
    bottom: 20px;
    left: 0;
    margin-left: -50px;
    display: block;
    text-align: left;
    font-weight: 400;
    font-size: 0.8rem;
    color: inherit;
    text-transform: uppercase;
    letter-spacing: 3px;
    white-space: nowrap;
    transform: rotate(270deg);
    transform-origin: 0 0;
    @media (max-width: 879px) {
      margin-left: -18px;
      bottom: 4px;
      display: none;
    }
  }
  @media (orientation: landscape) and (max-width: 879px) {
    max-width: 50vh;
  }
`

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
`

const Counter = styled.div`
  justify-self: flex-end;
`

function useEventListener(
  eventName,
  handler,
  element = typeof window !== "undefined" ? window : null
) {
  // Create a ref that stores handler
  const savedHandler = useRef()

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener
      if (!isSupported) return

      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event)

      // Add event listener
      element.addEventListener(eventName, eventListener)

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener)
      }
    },
    [eventName, element] // Re-run if eventName or element changes
  )
}

const CurrentProjectVideo = ({ srcWebM, srcMp4, i, index }) => {
  const [isMuted, setMuted] = useState(true)
  return (
    <>
      <StyledVideo
        autoPlay
        playsInline
        disablePictureInPicture
        muted={!isMuted && index === i ? false : true}
        loop
      >
        {srcWebM && <source src={srcWebM} type="video/webm" />}
        {srcMp4 && <source src={srcMp4} type="video/mp4" />}
      </StyledVideo>
      <MuteButton onClick={() => setMuted(!isMuted)}>
        {isMuted && <img className="mute" src={Mute} alt="" />}
        {!isMuted && <img className="mute" src={Unmute} alt="" />}
      </MuteButton>
    </>
  )
}

const ProjectImagesBox = ({ images, title, subtitle, text, movies }) => {
  const [index, setIndex] = useState(0)
  const [arr, setArr] = useState([])
  const [arrLength, setArrayLength] = useState(0)
  const [indexOfVideo, setIndexOfVideo] = useState(0)
  const [isLoaded, setIsLoaded] = useState(false)

  const fadeIn = useSpring({
    opacity: isLoaded ? 1 : 0,
  })

  const fadeInLeft = useSpring({
    opacity: isLoaded ? 1 : 0,
    transform: isLoaded ? "translate3d(0%, 0, 0)" : "translate3d(-200%, 0, 0)",
  })
  const fadeInRight = useSpring({
    opacity: isLoaded ? 1 : 0,
    transform: isLoaded ? "translate3d(0%, 0, 0)" : "translate3d(200%, 0, 0)",
  })

  useEffect(() => {
    let allObjectsArr
    if (movies && images) {
      allObjectsArr = [...images, ...movies]
      setIndexOfVideo(allObjectsArr.findIndex((item) => item.video))
    } else if (movies) {
      allObjectsArr = [...movies]
    } else {
      allObjectsArr = [...images]
    }
    setArrayLength(allObjectsArr.length - 1)
    setArr(allObjectsArr)
  }, [])

  const objects = arr.map((obj, i) => {
    console.log(obj)
    if (obj) {
      return obj.video ? (
        <StyledVideoWrapper
          key={i}
          isVisible={index === i ? true : false}
          title={title}
          subtitle={subtitle}
        >
          <CurrentProjectVideo
            i={i}
            index={index}
            srcWebM={obj.video.srcWebM}
            srcMp4={obj.video.srcMp4}
          />
        </StyledVideoWrapper>
      ) : (
        <StyledImage
          key={obj.childImageSharp.id}
          fluid={obj.childImageSharp.fluid}
          imgStyle={{ width: "100%", height: "auto" }}
          isVisible={index === i ? true : false}
          title={title}
          subtitle={subtitle}
          width={obj.childImageSharp.original.width}
          height={obj.childImageSharp.original.height}
          onLoad={() => setIsLoaded(true)}
        />
      )
    } else return null
  })

  const next = () => setIndex((state) => (state === arrLength ? 0 : state + 1))
  const back = () => setIndex((state) => (state === 0 ? arrLength : state - 1))

  useEventListener("keydown", keyPressEventHandler)

  function keyPressEventHandler({ key }) {
    if (key === "ArrowLeft") {
      back()
    }
    if (key === "ArrowRight") {
      next()
    }
  }

  return (
    <ProjectPageWrapper>
      <ImgBoxWrapper>
        <animated.div
          style={{
            width: "auto",
            minWidth: "10px",
            height: "100%",
            display: "flex",
            ...fadeInLeft,
          }}
        >
          {arrLength >= 1 && (
            <BoxControlButton onClick={back}>
              <img src={Back} alt="poprzednie zdjęcie" />
            </BoxControlButton>
          )}
        </animated.div>
        <ImageContainer>
          <ImageWrapper>
            {objects}
            {!text || text[0] === "" ? null : (
              <div className="scrollingText">
                <ScrollingText
                  text={text}
                  index={index}
                  indexOfVideo={indexOfVideo}
                  movies={movies ? true : false}
                  isLoaded={isLoaded}
                />
              </div>
            )}
          </ImageWrapper>
          {arrLength >= 1 && (
            <AnimatedCounter style={fadeIn}>
              {index + 1} | {arrLength + 1}
            </AnimatedCounter>
          )}
        </ImageContainer>
        <animated.div
          style={{
            width: "auto",
            minWidth: "10px",
            height: "100%",
            display: "flex",
            ...fadeInRight,
          }}
        >
          {arrLength >= 1 && (
            <BoxControlButton onClick={next} flexEnd>
              <img src={Next} alt="następne zdjęcie" />
            </BoxControlButton>
          )}
        </animated.div>
      </ImgBoxWrapper>
    </ProjectPageWrapper>
  )
}

const AnimatedCounter = animated(Counter)

export default ProjectImagesBox
